<template>
	<div id="app">
		<div id="nav">
			<section class="hero is-fullheight">
				<div class="hero-head">
					<router-view name="navbar"></router-view>
				</div>
				<div class="hero-body">
					<router-view />
					<router-view name="form"></router-view>
				</div>
			</section>
			<footnote></footnote>
		</div>
	</div>
</template>

<script>
import footnote from '@/components/footnote';

export default {
	components: { footnote }
};
</script>

<style>
.image {
	margin: auto;
}

.checkout {
	max-width: 450px;
	margin: auto;
}

.input {
	box-shadow: 0 1px 3px 0 #e6ebf1 !important;
}

.button.is-primary.shadow {
	box-shadow: 0 1px 3px 0 #e6ebf1 !important;
}

button {
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.overlay {
	box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
}
</style>

<style lang="scss">
@import '~bulma/sass/utilities/_all';

// Set your colors
$primary: #ebcfc4;
$primary-invert: findColorInvert($primary);

$white: #f2f0f1;
$white-invert: findColorInvert($white);

$dark: #0a090e;
$dark-invert: findColorInvert($dark);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
	'white': (
		$white,
		$white-invert
	),
	'black': (
		$black,
		$white
	),
	'light': (
		$light,
		$light-invert
	),
	'dark': (
		$dark,
		$dark-invert
	),
	'primary': (
		$primary,
		$primary-invert
	),
	'info': (
		$info,
		$info-invert
	),
	'success': (
		$success,
		$success-invert
	),
	'warning': (
		$warning,
		$warning-invert
	),
	'danger': (
		$danger,
		$danger-invert
	)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$footer-background-color: #070a0a;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';
</style>
