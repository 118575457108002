import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		components: {
			default: () => import(/* webpackChunkName: "home" */'../views/Home.vue'),
			navbar: null
		},
		props: {

		}
	},
	{
		path: '/complete',
		name: 'complete',
		components: {
			default: () => import(/* webpackChunkName: "complete" */'../views/Complete.vue'),
			navbar: () => import('../components/navigation/returnbar.vue')
		}
	},
	{
		path: '/p',
		name: 'Pay',
		components: {
			default: () => import(/* webpackChunkName: "pay" */'../views/Pay.vue'),
			navbar: () => import('../components/navigation/returnbar.vue')
		},
		children: [
			{
				path: 'hotmess',
				component: () => import(/* webpackChunkName: "hotmess" */'../components/form/hotmess.vue'),
			},
			{
				path: 'dud2stud',
				component: () => import(/* webpackChunkName: "dud2stud" */'../components/form/dud2stud.vue'),
			}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router

/*

{
	path: '/about',
	name: 'About',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: () => import(/* webpackChunkName: "about" */ //'../views/About.vue')
  //}
//*/